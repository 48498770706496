@font-face {
  font-family: "SF-Pro300";
  /* EloquiaTextLight */
  src: url("./fonts/EloquiaDisplayLight/font.woff2") format("woff2"),
      url("./fonts/EloquiaDisplayLight/font.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "SF-Pro400";
  /* EloquiaTextRegular */
  src: url("./fonts/EloquiaDisplayRegular/font.woff2") format("woff2"),
    url("./fonts/EloquiaDisplayRegular/font.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "SF-Pro500";
  /* EloquiaTextMedium */
  src: url("./fonts/EloquiaDisplayMedium/font.woff2") format("woff2"),
      url("./fonts/EloquiaDisplayMedium/font.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "SF-Pro600";
  /* EloquiaDisplaySemiBold */
  src: url("./fonts/EloquiaDisplaySemiBold/font.woff2") format("woff2"),
      url("./fonts/EloquiaDisplaySemiBold/font.woff") format("woff");
  font-weight: 600;
}